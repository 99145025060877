<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Contacts
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
        @click="addContact()"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Contact</span>
      </button>
    </div>

    <div class="flex flex-wrap -m-3">
      <template v-for="(contact, i) in sortedContacts">
        <div class="p-3 w-full lg:w-1/2 xl:w-1/3 flex" :key="i">
          <div
            class="w-full bg-gray-200 p-4 rounded flex flex-col justify-between"
          >
            <div>
              <div class="mb-2 flex items-center">
                <h4 class="text-xl text-secondary font-bold">
                  {{
                    contact.is_primary
                      ? "Primary Contact"
                      : "Additional Contact"
                  }}
                </h4>
                <a
                  class="ml-2 hover:bg-white rounded-lg p-2"
                  v-if="contact.email"
                  :href="'mailto:' + contact.email"
                >
                  <svg
                    class="text-orange-700 stroke-current h-5 w-5"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                    />
                    <path d="M22 6l-10 7L2 6" />
                  </svg>
                </a>
              </div>
              <div class="flex items-center py-1">
                <svg
                  class="text-orange-700 stroke-current h-6 w-6 mr-2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                  <circle cx="12" cy="7" r="4" />
                </svg>
                <p
                  v-if="contact.job_title"
                  class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2"
                >
                  {{ contact.job_title }}
                </p>
                <p class="rounded-lg px-2 py-1">
                  <span v-if="contact.firstname">{{
                    contact.firstname + " "
                  }}</span>
                  <span v-if="contact.lastname">{{ contact.lastname }}</span>
                </p>
              </div>
              <div class="flex items-center py-1">
                <svg
                  class="text-orange-700 stroke-current h-6 w-6 mr-2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                  />
                  <path d="M22 6l-10 7L2 6" />
                </svg>
                <a
                  class="text-blue-800 hover:bg-white rounded-lg px-2 py-1"
                  :href="'mailto:' + contact.email"
                  >{{ contact.email }}</a
                >
              </div>
              <div class="flex items-center py-1">
                <svg
                  class="text-orange-700 stroke-current h-6 w-6 mr-2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
                  />
                </svg>
                <a
                  v-if="contact.mobile"
                  class="text-blue-800 hover:bg-white rounded-lg px-2 py-1"
                  :href="'tel:' + contact.mobile"
                  >{{ contact.mobile }}</a
                >
                <a
                  v-if="contact.telephone"
                  class="text-blue-800 hover:bg-white rounded-lg px-2 py-1"
                  :href="'tel:' + contact.telephone"
                  >{{ contact.telephone }}</a
                >
              </div>
            </div>
            <div class="mt-4 -mx-2 flex justify-center">
              <button
                @click="editContact(contact)"
                type="button"
                class="mx-2 flex justify-center items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                  />
                </svg>
                <span class="flex-1">Edit Contact</span>
              </button>
              <button
                v-if="!contact.is_primary"
                @click="promoteContactToPrimary(contact)"
                type="button"
                class="mx-2 flex justify-center items-center bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="8" r="7" />
                  <path d="M8.21 13.89L7 23l5-3 5 3-1.21-9.12" />
                </svg>
                <span class="flex-1">Make Primary</span>
              </button>
              <button
                v-if="!contact.is_primary"
                @click="deleteContact(contact)"
                type="button"
                class="mx-2 flex justify-center items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                  />
                </svg>
                <span class="flex-1">Delete</span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditContactPanelOpen"
        @close="addEditContactPanelOpen = false"
        :title="editingContact.contact_id ? 'Edit Contact' : 'Add Contact'"
      >
        <add-edit-contact :contact="editingContact" @complete="saveContact" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddEditContact = () =>
  import("@/components/SalesProspects/Edit/AddEditContact.vue");

export default {
  name: "Contact",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditContact,
  },
  props: {
    contacts: Array,
    enquiryId: String,
  },
  data() {
    return {
      addEditContactPanelOpen: false,
      editingContact: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  computed: {
    sortedContacts: function() {
      return this.$_.orderBy(
        this.contacts,
        ["is_primary", "job_title"],
        ["desc", "asc"]
      );
    },
  },
  methods: {
    addContact: function() {
      this.editingContact = {};
      this.addEditContactPanelOpen = true;
    },
    editContact: function(contact) {
      this.editingContact = { ...contact };
      this.addEditContactPanelOpen = true;
    },
    saveContact: async function(contact) {
      // Ensure contact has mandatory fields filled out
      if (!contact.email & !contact.telephone) {
        let msg = contact.is_primary
          ? "Please add either an email or telephone for the primary contact"
          : "Please add either an email or telephone for the additional contact";

        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              "Missing fields"
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditContactPanelOpen = false;

      try {
        if (!contact.contact_id) {
          contact.is_primary = false;
          let createResult = await this.SalesProspectService.createProspectContact(
            this.enquiryId,
            contact
          );
          contact.contact_id = createResult.data.contact_id;
          this.contacts.push(contact);
        } else {
          await this.SalesProspectService.updateProspectContact(
            this.enquiryId,
            contact.contact_id,
            contact
          );
          let idx = this.$_.findIndex(
            this.contacts,
            (c) => c.contact_id == contact.contact_id
          );
          this.contacts.splice(idx, 1, contact);
        }

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Contact details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !contact.contact_id
          ? "There was a problem creating the new contact"
          : "There was a problem updating the contact";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the contact"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handlePromoteContactToPrimary: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_primary = true;
          await this.saveContact(this.confirmAlert.data);
          this.contacts.forEach((c) => {
            if (c.contact_id !== this.confirmAlert.data.contact_id) {
              c.is_primary = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the contact"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    promoteContactToPrimary: function(contact) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteContactToPrimary,
        message: `Change primary contact to '${contact.firstname} ${contact.lastname}'?`,
        showing: true,
        data: contact,
      };
    },
    handleDeleteContact: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.SalesProspectService.deleteProspectContact(
            this.enquiryId,
            this.confirmAlert.data.contact_id
          );
          let idx = this.$_.findIndex(this.contacts, (c) => {
            return c.contact_id == this.confirmAlert.data.contact_id;
          });

          this.$delete(this.contacts, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Contact Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted contact"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the contact"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.isBusy = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteContact: function(contact) {
      // If we're deleting a contact that hasn't been saved yet
      if (String(contact.contact_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.contacts, (c) => {
          return c.contact_id == contact.contact_id;
        });

        this.$delete(this.contacts, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteContact,
        message: `Are you sure you wish to delete '${contact.firstname} ${contact.lastname}' This cannot be undone.`,
        showing: true,
        data: contact,
      };
    },
  },
};
</script>
